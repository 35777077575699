<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Code</label>
            <b-form-select
              v-model="filter.code_project"
              :options="projectOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Name</label>
            <b-form-input
              id="input-large"
              size="lg"
              disabled
              placeholder="Project Name"
              v-model="selectedProject.projectName"
            ></b-form-input>
          </div>
        </div>

        <div class="col-md-12 py-2 col-sm-12">
          <label for="input-large">Outstanding Category</label>
          <b-form-select
            v-model="filter.distribusi"
            :options="formattedCategoryOutstandingOptions"
            size="lg"
          ></b-form-select>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="onShowData(false)">
            <i class="flaticon2-search-1"></i> Show
          </button>

          <button class="btn btn-warning btn-md ml-2" @click="onShowData(true)">
            <i class="flaticon2-search-1"></i> Show Latest
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="row mb-5">
        <div class="col-md-4 py-2 col-sm-12">
          <vue-excel-xlsx
            :data="data.shopDrawingExt"
            :columns="columns"
            :file-name="
              filter.code_project +
                '-' +
                selectedProject.nickname +
                '- Design External'
            "
            :sheet-name="'sheet1'"
          >
            <button class="btn btn-success btn-md mr-2">
              <i class="fa fa-file-excel" /> Excel
            </button>
          </vue-excel-xlsx>

          <button class="btn btn-danger btn-md mr-2" @click="onExportPDF">
            <i class="fa fa-file-pdf" /> PDF
          </button>
        </div>

        <div class="col-md-4 offset-md-4 py-2 col-sm-12">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="flaticon2-search-1"></i>
              </span>
            </div>

            <input
              type="text"
              class="form-control"
              placeholder="Search"
              v-model="filter.search"
            />
          </div>
        </div>
      </div>

      <div class="card-body p-0">
        <complete-table :loading="false" :data="this.data.shopDrawingExt">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 50px" class="pl-7">
                <span class="text-dark-75">No</span>
              </th>
              <th style="min-width: 100px" class="pl-7">
                <span class="text-dark-75">Numbering</span>
              </th>
              <th style="min-width: 100px">Title</th>
              <th style="min-width: 100px">Station</th>
              <th style="min-width: 100px">Type</th>
              <th style="min-width: 100px">Scope</th>
              <th style="min-width: 100px">Status</th>
              <th style="min-width: 100px">Revision</th>
              <th style="min-width: 100px">Deadline</th>
              <th style="min-width: 101px">PIC Drafter</th>
              <th style="min-width: 101px">Received Date</th>
              <th style="min-width: 101px">Receiver</th>
              <th style="min-width: 101px">Transmittal Number</th>
              <th style="min-width: 101px">Description</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr v-bind:key="i">
              <td>
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.no }}
                </span>
              </td>

              <td>
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.numbering }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.title }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.stasiun }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.type }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.scope }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.status }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.revisi }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ moment(item.deadline).format("DD-MM-YYYY") }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.pic_drafter }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ moment(item.received_date).format("DD-MM-YYYY") }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.eksternal_receiver ? item.eksternal_receiver : "-" }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.transmittal_number }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.keterangan }}
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_REPORT_SHOP_DRAWING_EXT } from "@/core/services/store/report-shop-drawing-ext.module";
import { mapGetters, mapState } from "vuex";
import { GET_PROJECT_DETAIL } from "@/core/services/store/project.module.js";
import jsPDF from "jspdf";
import moment from "moment";
import { logos } from "@/assets/js/components/logos64bit.js";
import "jspdf-autotable";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import {
  arrayMoreThanOne,
  formattedOptions,
} from "../../../core/helper/array-validation.helper";
import { GET_OUTSTANDING_CATEGORY } from "../../../core/services/store/outstanding-category.module";

export default {
  name: "ShopDrawingExternal",
  components: {
    CompleteTable,
  },
  data() {
    return {
      filter: {
        date: null,
        code_project: null,
        code: null,
        search: null,
        distribusi: null,
        jobdesk: [
          "interface",
          "design",
          "planner",
          "drafter",
          "koor",
          "enginer",
          "manager",
          "admin",
        ],
      },
      selectedProject: {
        code: null,
        ownerName: null,
        projectName: null,
        status: null,
        contractNumber: null,
        primpro: null,
        revisi: null,
        nickname: null,
        contract_no: null,
        eksternal_receiver: null,
      },
      columns: [
        {
          label: "No",
          field: "no",
        },
        {
          label: "Numbering",
          field: "numbering",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Station",
          field: "stasiun",
        },
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Scope",
          field: "scope",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Revision",
          field: "revisi",
        },
        {
          label: "Deadline",
          field: "deadline",
        },
        {
          label: "PIC Drafter",
          field: "pic_drafter",
        },
        {
          label: "Received Date",
          field: "received_date",
        },
        {
          label: "Receiver",
          field: "eksternal_receiver",
        },
        {
          label: "Transmittal Number",
          field: "transmittal_number",
        },
        {
          label: "Description",
          field: "keterangan",
        },
      ],
      data: {
        shopDrawingExt: [],
        shopDrawingExtOri: [],
      },
      options: {
        project: [{ value: null, text: null }],
        pic: [{ value: null, text: null }],
        distribusi: [
          {
            value: "all",
            text: "all",
          },
          {
            value: "len",
            text: "dl design len",
          },
          {
            value: "shd",
            text: "design eksternal",
          },
          {
            value: "len_shd",
            text: "dl design len & design eksternal",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentReportShopDrawingExt",
      "currentPics",
      "currentProjectDetails",
      // "currentOutstandingCategory"
    ]),
    ...mapState({
      categoryOutstanding: (state) =>
        state.outstandingCategory.outstandingCategories?.data,
    }),
    projectOptions() {
      let result = [{ value: null, text: "Choose Project" }];

      if (this.currentProjectDetails !== undefined) {
        this.currentProjectDetails.map((project) => {
          result.push({
            value: project.code,
            text: project.code + "-" + project.nickname,
          });
        });
      }

      return result;
    },

    currentProjectCode() {
      return this.filter.code_project;
    },

    currentSearch() {
      return this.filter.search;
    },

    formattedCategoryOutstandingOptions() {
      return formattedOptions(this.categoryOutstanding);
    },
  },
  watch: {
    currentSearch(value) {
      console.log(value);
      if (!value) {
        console.log("kosong");
        this.onShowData();
        return;
      }

      this.data.shopDrawingExt = this.data.shopDrawingExtOri.filter(
        (c) =>
          c.numbering.includes(value) ||
          c.title.includes(value) ||
          c.stasiun.includes(value) ||
          c.type.includes(value) ||
          c.scope.includes(value) ||
          c.status.includes(value) ||
          c.revisi.includes(value) ||
          c.deadline.includes(value) ||
          c.pic_drafter.includes(value) ||
          c.received_date.includes(value) ||
          c.eksternal_receiver.includes(value) ||
          c.transmittal_number.includes(value) ||
          c.keterangan.includes(value)
      );
    },

    currentProjectCode(value) {
      if (!value) {
        Object.assign(this.selectedProject, {
          id_project: null,
          ownerName: null,
          projectName: null,
          status: null,
          contractNumber: null,
          revisi: null,
          primpro: null,
          nickname: null,
          contract_no: null,
        });

        return;
      }

      let resultProject = this.currentProjectDetails.filter(
        (c) => c.code === value
      );

      Object.assign(this.selectedProject, {
        id_project: resultProject[0].code,
        ownerName: resultProject[0].owner,
        projectName: resultProject[0].name,
        status: resultProject[0].status,
        contractNumber: resultProject[0].contract_no,
        primpro: resultProject[0].leader,
        revisi: resultProject[0].site_document_revision,
        nickname: resultProject[0].nickname,
        contract_no: resultProject[0].contract_no,
      });

      //this.getDocumentSiteByProject(value);
    },
  },
  methods: {
    onPDF() {
      console.log(this.currentReportLettering);
    },

    onShowData(latest) {
      var data = {
        project_id: this.selectedProject.id_project,
        params: {
          distribution: this.filter.distribusi,
        },
        latest,
      };
      this.$store
        .dispatch(GET_REPORT_SHOP_DRAWING_EXT, data)
        .then(() =>
          this.mappingDataShopDrawingExt(this.currentReportShopDrawingExt)
        );
    },

    mappingDataProject(projectList) {
      this.options.project = [];
      projectList.map((project) =>
        this.options.project.push({
          text: project.code + "-" + project.nickname,
          value: project.code,
        })
      );
    },

    mappingDataShopDrawingExt(listDrawing) {
      let no = 0;
      this.data.shopDrawingExt = [];
      listDrawing.map((drawing) =>
        this.data.shopDrawingExt.push({
          no: (no = no + 1),
          numbering: drawing.numbering,
          title: drawing.title === null ? "" : drawing.title,
          stasiun: drawing.station === null ? "" : drawing.station?.name,
          type: drawing.type === null ? "" : drawing.type?.name,
          scope: drawing.scope === null ? "" : drawing.scope?.name,
          status: drawing.last_status === null ? "" : drawing.last_status?.code,
          revisi:
            drawing.revision_version === null ? "" : drawing.revision_version,
          deadline:
            drawing.deadline === null
              ? ""
              : moment(drawing.deadline).format("DD-MM-YYYY"),
          pic_drafter:
            drawing.drafter_pic === null ? "" : drawing.drafter_pic?.name,
          received_date:
            drawing.date_receive === null
              ? ""
              : moment(drawing.date_receive).format("DD-MM-YYYY"),
          eksternal_receiver:
            drawing.eksternal_receiver === null
              ? ""
              : drawing.eksternal_receiver.name,
          transmittal_number:
            drawing.transmittal_number === null
              ? ""
              : drawing.transmittal_number,
          keterangan: drawing.shd_comment === null ? "" : drawing.shd_comment,
        })
      );

      this.data.shopDrawingExtOri = [...this.data.shopDrawingExt];
    },

    onExportPDF() {
      moment.locale("id");
      /*  var lMargin = 15; //left margin in mm
      var rMargin = 15; //right margin in mm
      var pdfInMM = 210; // width of A4 in mm */
      var doc = new jsPDF("landscape");

      doc.setFontSize(7);
      const today = new Date();
      var totalPagesExp = "{total_pages_count_string}";

      var logo = logos;

      var head = [
        [
          "No",
          "Numbering",
          "Title",
          "Station",
          "Type",
          "Scope",
          "Status",
          "Revision",
          "Deadline",
          "PIC Drafter",
          "Received Date",
          "Receiver",
          "Transmittal Number",
          "Description",
        ],
      ];
      var body = [];
      var projectCode =
        this.filter.code_project !== null ? this.filter.code_project : "-";

      var nickname =
        this.selectedProject.nickname !== null
          ? this.selectedProject.nickname
          : "-";
      var contract_no =
        this.selectedProject.contract_no !== null
          ? this.selectedProject.contract_no
          : "-";

      this.data.shopDrawingExt.map((c) => {
        const propertyValues = Object.values(c);
        body.push(propertyValues);
      });
      doc.autoTable({
        styles: {
          cellPadding: 0.5,
          fontSize: 7,
        },
        headStyles: {
          halign: "center",
          valign: "middle",
        },
        head: head,
        body: body,
        didDrawPage: function(data) {
          // Header
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.addImage(logo, "png", data.settings.margin.left, 10, 25, 15);
          doc.text(
            "Reporting Monitoring Eksternal",
            data.settings.margin.left + 50,
            25
          );
          doc.setFontSize(8);

          doc.text(
            `Day/Date : ${moment(today).format("dddd")}/ ${moment(today).format(
              "ll"
            )}`,
            data.settings.margin.left + 200,
            15
          );
          doc.text(
            `Time :  ${moment(today).format("LTS")}`,
            data.settings.margin.left + 200,
            20
          );

          doc.text(
            `Contract No: ${contract_no}`,
            data.settings.margin.left + 200,
            25
          );

          doc.text(
            `Project Code: ${projectCode}`,
            data.settings.margin.left + 200,
            30
          );

          doc.text(
            `Project Name: ${nickname}`,
            data.settings.margin.left + 200,
            35
          );
          /*
          var lines = doc.splitTextToSize(
            `Project name: ${projectName}`,
            pdfInMM - lMargin - rMargin
          );
          doc.text(lines, data.settings.margin.left, 45); */

          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(10);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          var footer_lrs = 'PT. LEN RAILWAY SYSTEMS';
          doc.text(str, 260, pageHeight - 10);
          doc.text(footer_lrs, data.settings.margin.left, pageHeight - 10);
        },
        margin: { top: 45 },
      });

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }
      var shopDrawingName =
        this.filter.code_project +
        "-" +
        this.selectedProject.nickname +
        "- Design Eksternal.pdf";

      doc.save(shopDrawingName);
    },
    getOutstandingCategory() {
      if (!arrayMoreThanOne(this.categoryOutstanding)) {
        this.$store.dispatch(GET_OUTSTANDING_CATEGORY, {});

        console.log("category", this.categoryOutstanding);
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Report Design External" },
    ]);
    this.$store
      .dispatch(GET_PROJECT_DETAIL, {
        jobdesk: "design",
        params: this.filter.jobdesk,
      })
      .then(() => this.mappingDataProject(this.currentProjectDetails));
    this.$store
      .dispatch(GET_REPORT_SHOP_DRAWING_EXT)
      .then(() => this.mappingDataProject(this.currentReportShopDrawingExt));
    this.$store.dispatch(GET_OUTSTANDING_CATEGORY);
  },
};
</script>

<style></style>
